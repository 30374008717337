import React from 'react'

const CategoryIcon = (props) => {
    let icon;

    if (props.categoryCode === 'FOOD_DRINK')
        icon = <img src='/images/category_icons/FOOD_DRINK.png' height={40}/>
    if (props.categoryCode === 'ACTIVE')
        icon = <img src='/images/category_icons/ACTIVE.png' height={40}/>
    if (props.categoryCode === 'STAY')
        icon = <img src='/images/category_icons/STAY.png' height={40}/>
    if (props.categoryCode === 'HIRE_TSPT')
        icon = <img src='/images/category_icons/HIRE_TSPT.png' height={40}/>
    if (props.categoryCode === 'TOURS')
        icon = <img src='/images/category_icons/TOURS.png' height={40}/>
    if (props.categoryCode === 'INFO')
        icon = <img src='/images/category_icons/INFO.png' height={40}/>
    if (props.categoryCode === 'BEAUTY')
        icon = <img src='/images/category_icons/BEAUTY.png' height={40}/>
    if (props.categoryCode === 'SERVICES')
        icon = <img src='/images/category_icons/SERVICES.png' height={40}/>
    if (props.categoryCode === 'SHOP')
        icon = <img src='/images/category_icons/SHOP.png' height={40}/>
    if (props.categoryCode === 'OTHER') 
        icon = <img src='/images/category_icons/OTHER.png' height={40}/>

    return (
        icon
    )
}

export default CategoryIcon