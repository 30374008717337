import { Grid, Container, ThemeProvider } from '@mui/material'
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { Box } from '@mui/system'
import { Typography } from "@mui/material"

import useBrochureService from '../shared/services/BrochureService'
import SectionHeader from '../shared/components/SectionHeader'
import { useNavigate, useSearchParams } from "react-router-dom";
import { desktopTheme } from '../themeDesktop';

const StreetsIndex = () => {
    const { getStreetsIndexByArea }  = useBrochureService()
    const [mapArea, setMapArea] = useState()
    const [streetsIndexs, setStreetsIndexs] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        async function getAreaStreetsIndex(ma) {
            let data = await getStreetsIndexByArea(ma);
            setStreetsIndexs(data);
        }

        function init() {
            const ma = searchParams.get("mapArea");
            getAreaStreetsIndex(ma);
            setMapArea(ma)
        }
        init();

    }, [ ])

    const MapStreetsIndex = () => {
        const area = "";
        return (
            <>
                <Box sx={{mt:7}}></Box>
                <SectionHeader title={'Streets index for ' + streetsIndexs[0].mapArea.name + ' area.'} />
                <Box mt={0} mb={2} bgcolor={'background.paper'}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                {
                                    streetsIndexs.map((streetsIndex) => {
                                        return (
                                            <>
                                                <Grid item flexGrow={1} xs={12} sm={4} md={3} lg={2} sx={{ padding: 0.8 }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', minWidth: 50, pl:1, pt:1.25, backgroundColor: "white", height: "40px", alignContent: "center", textJustify :"center"}} height={40} overflow='auto'  >
                                                            <Box sx={{width: 200}}>
                                                                <Typography variant="body3" display={"inline"} width="100%" >{streetsIndex.streetName}</Typography>
                                                            </Box>
                                                            <Box sx={{mr: 1}}>
                                                                <Typography variant="body3" display={"inline"} width="100%" >{streetsIndex.coordinateX}{streetsIndex.coordinateY}</Typography>
                                                            </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }


    return (

        <ThemeProvider theme={desktopTheme}>
            <section className='desktop-background'>
                <Container>
                    {
                        streetsIndexs.length > 0 && <MapStreetsIndex></MapStreetsIndex>
                    }
                </Container>
            </section>
        </ThemeProvider>
    )
}

export default StreetsIndex


