import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useBrochureService from '../shared/services/BrochureService';
import { BROCHURE_TYPES, EVENT_CODES } from '../shared/constants/AppConsts';

const Ad = () => {
    const { addBorchureStats, getBrochuresResponse } = useBrochureService()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function getAd() {
            let brochureId = searchParams.get("adId");

            let response = await getBrochuresResponse(null, null, null, brochureId, null, null, true);
            if (response.status === 201) { // this is a PASS_THROUGH_AD, redirect to google maps URL
                await addBorchureStats(brochureId, EVENT_CODES.DETAIL_PAGE_VIEW);
                // console.log(response.data.content[0].googleAdUrl);
                // window.location.href = response.data.googleAdUrl;
                window.location.href = response.data.content[0].googleAdUrl;
            } else if (response.status === 204) { // Ad has expired
                navigate(`/`);
            } else if (BROCHURE_TYPES.BROCHURE_AD.code === response.data.content[0]?.type){
                navigate(`/details/brochure-ad/${brochureId}`); 
            } else {
                navigate(`/details/${brochureId}`); // LANDING_PAGE_AD Ad, render LANDING_PAGE_AD ad
            }
        }
        getAd();
    }, [])

    return (
        <></>
    )
}

export default Ad