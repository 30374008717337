import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { mobileTheme } from '../../themeMobile'

function Footer() {
    const aboveSM = useMediaQuery(mobileTheme.breakpoints.up('md'));


    return (
        <Box sx={{ p: 1, backgroundColor: mobileTheme.palette.background.paper, textAlign: 'center', height: '40px' }}>
            <Typography variant='caption'>
                <Link to={'/terms-and-conditions'} style={{ textDecoration: 'none', color: 'inherit' }}>Terms and Conditions</Link>
                &nbsp; | &nbsp;
                <Link to={'/privacy-policy'} style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link>
            </Typography>
        </Box>
    )
}

export default Footer