
import { createTheme } from '@mui/material';
import { blue, deepOrange, grey } from '@mui/material/colors';

export const mobileTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#008080',
            contrastText: '#fff'
        },
        secondary: {
            main: '#FF9800',
            contrastText: '#000'
        },
        info: {
            main: '#3DBFF2',
            contrastText: '#000'
        },
        warning: {
            main: '#F2BD1D',
            contrastText: '#000'
        },
        success: {
            main: '#66D9D9',
            contrastText: '#000'
        },
        background: {
            paper: grey['200'],
            paperdark: grey['300'],
            default: '#fff'
        },
        chipSelected: {
            main: deepOrange['500'],
            contrastText: '#fff'
        },
        chipDefault: {
            main: grey['300'],
            contrastText: '#000'
        },
        facebook: {
            main: blue[500],
            contrastText: '#fff'
        },
        instagram: {
            main: '#9534BF',
            contrastText: '#fff'
        },
        text: {
            primary: '#000',
            secondary: grey['700'],
            default: grey['700'],
            white: '#fff'
        },
    },
    typography: {
        fontSize: 16,
        fontWeightRegular: '400',
        // fontFamily: 'Gilroy,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        fontFamily: 'Cocogoose,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
    }
})
