import React from 'react'
import ArchiveIcon from '@mui/icons-material/Archive';
import PageHeader from '../../shared/components/PageHeader'
import BrochuresTable from './BrochuresTable';

const icon = <ArchiveIcon />

const ArchivedBrochures = () => {
    return (
        <section>
            <PageHeader title='List of Archived Ads' icon={icon} subTitle='View archived Ads, trigger stats emails etc.'/>
            <BrochuresTable archived={true} />
        </section>
    )
}

export default ArchivedBrochures