import React from 'react';
import { Box, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

const SectionHeader = (props) => {
    var isEditMode = props.edit==false ? false : true

    const EditInfoIcon = () => {
        return (<>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <EditIcon color="action" onClick={ () =>{ props.onClick(props) }}></EditIcon>
            </Box>
        </>)
    }

    return (
        <Box>
            <Box display={'inline-flex'} gap={3} mt={1} p={1} pl={3} bgcolor={'grey.100'} width={'100%'}  alignItems={'center'}>
                <Box sx={{ width: '100%', display: 'row', justifyContent: 'flex-start' }} >
                    <Typography variant='h6'>{props.title}</Typography>
                    {  props.subTitle && <Typography sx={{ fontStyle: 'italic' }} variant='body2' color={'grey.900'}>{props.subTitle}</Typography>}
                </Box>
                { !isEditMode && !props.neverEdit && <EditInfoIcon/>}
            </Box>
        </Box>
    )

}

export default SectionHeader