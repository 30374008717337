import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const DesktopFooter = () => {
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Grid container justifyContent={'center'} bgcolor="grey.500">
                <Grid item display={'inline-flex'} color={'grey.dark'}>
                    <Typography variant='body2'>&copy; LocalOffer4U &nbsp; | </Typography>
                    <Typography variant='body2'>&nbsp; Privacy Policy</Typography>
                </Grid>
            </Grid>
            
        </Paper>
    )
}

export default DesktopFooter