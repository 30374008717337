import { USER_ROLES } from '../../shared/constants/AppConsts';

const useUserRole = () => {

    const getUser = () => {
        return localStorage.getItem('USER')
    }

    const gIsAdmin = () => {
        let uRole = localStorage.getItem('USER_ROLE');
        return (uRole && uRole === USER_ROLES.ADMINISTRATOR);
    }

    const gIsVendor = () => {
        let uRole = localStorage.getItem('USER_ROLE');
        return (uRole && uRole === USER_ROLES.VENDOR);
    }

    return{
        getUser,
        gIsAdmin,
        gIsVendor
    }
}

export default useUserRole