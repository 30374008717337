import { Container, ThemeProvider, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import DesktopAppBar from '../shared/components/DesktopAppBar'
import DesktopFooter from '../shared/components/DesktopFooter';
import { desktopTheme } from '../themeDesktop';
import { CommonContext } from '../shared/context/CommonContext';
import { useState } from 'react';

const VendorLayout = () => {

    const aboveSM = useMediaQuery(desktopTheme.breakpoints.up('md'));
    const [loading, setLoading] = useState(false)

    return (
        <ThemeProvider theme={desktopTheme}>
            <section className='desktop-background'>
                <CommonContext.Provider value={{ loading, setLoading }}>
                    <DesktopAppBar />
                    <Container>
                        <Outlet />
                    </Container>
                    {aboveSM && <DesktopFooter />}
                </CommonContext.Provider>
            </section>
        </ThemeProvider>
    )
}

export default VendorLayout