import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { EVENT_CODES } from '../../shared/constants/AppConsts';
import useBrochureService from '../../shared/services/BrochureService';

const VendorRecirect = () => {
    const { addBorchureStats } = useBrochureService()
    const [searchParams] = useSearchParams();


    const recordVendorStat = async () => {
        let response = await addBorchureStats('debbc630-73b2-4e91-b853-c1af7276b7e8', EVENT_CODES.DETAIL_PAGE_VIEW);
        window.location.href = searchParams.get("vendorUrl");
    }

    useEffect(() => {
        recordVendorStat();
    }, [])

    return (
        <></>
    )
}

export default VendorRecirect