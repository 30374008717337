import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, Button, Fade, Grid, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../shared/components/WindowDimension';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import styled from '@emotion/styled';
import { getActiveCategories, getCategories } from '../shared/services/CategoryService';
import { MobileContext } from '../shared/context/MobileContext';
import { useNavigate } from 'react-router-dom';
import { removeScrollPosition } from '../shared/util/utils';
import useBrochureService from '../shared/services/BrochureService';
import { mobileTheme } from '../themeMobile';
import { BROCHURE_TYPES } from '../shared/constants/AppConsts';

const StyledMenu = styled(Menu)(({ theme }) => ({
    '.MuiMenu-paper': {
        right: 0,
        backgroundColor: theme.palette.background.default
    },
}));

const TitleBarSM = (props) => {
    const { getMapAreas } = useBrochureService();
    const [mapAreasData, setMapAreasData] = useState([]);
    const [mapAnchorEl, setMapAnchorEl] = useState(null);
    const { setSelectedCategory, setSelectedMapArea, BLDfilter, toggleBLDfilter, BROfilter, toggleBROfilter, BROfilterText } = useContext(MobileContext);
    const { width } = useWindowDimensions();
    const [mainAnchorEl, setMainAnchorEl] = useState(null);
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const open = Boolean(mainAnchorEl);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    const allRefs = useRef([]);
    const anchorRefMap = useRef();
    const anchorRefMenu = useRef();

    const [isOpenIndex, setIsOpenIndex] = useState(-1);

    useEffect(() => {
        allRefs.current = allRefs.current.slice(0, categories.length)
    }, [categories])

    const handleHamburgerMenuClick = (event) => {
        setMainAnchorEl(event.currentTarget);
    }

    const onMenuItemClick = (categoryCode) => {
        setMainAnchorEl(null);
        removeScrollPosition()
        setSelectedCategory(categoryCode);
        navigate('/');
    }

    const onCloseClick = () => {
        setMainAnchorEl(null);
    }

    const onAllBrochuresClick = () => {
        removeScrollPosition()
        setMainAnchorEl(null);
        setSelectedCategory('');
        setSelectedMapArea('');
        navigate('/');
    }

    const onMapViewClick = () => {
        setMainAnchorEl(null);
        setSelectedCategory('');
        setSelectedMapArea('');
        navigate('/brochureMap');
    }

    const onMapsInPDFclick = () => {
        setMainAnchorEl(null);
        setSelectedCategory('');
        setSelectedMapArea('');
        navigate('/maps-download');
    }

    const handleMapAreaSelect = (mapArea) => {
        removeScrollPosition()
        setMapAnchorEl(null);
        setMapIsOpen(false);
        setSelectedMapArea(mapArea.did);
        navigate('/');
    };

    const handleMapClose = (mapArea) => {
        setMapAnchorEl(null);
        setMapIsOpen(false);
    };

    const handleMapClick = (event) => {
        //setMapAnchorEl(event.currentTarget);
        setMapIsOpen(true);
    };

    useEffect(() => {
        async function getCategoryData() {
            console.log('BROfilter', BROfilter);
            const activeTypes = BROfilter ? 'BRO_AD' : 'LP_AD,BRO_AD';
            let data = await getActiveCategories(activeTypes);
            let Items = [];
            for (let category of data) {
                let subCatsList = [];
                for (let subCat of category.subCategories) {
                    subCatsList.push({ 'code': subCat.code, 'name': subCat.name })
                }
                Items.push({ 'code': category.code, 'name': category.name, 'subCategories': subCatsList })
            }
            setCategories(Items);
        }
        async function getMapAreaData() {
            let mapAreaArray = [];
            let data = await getMapAreas();
            for (let mapArea of data) {
                mapAreaArray.push({ 'did': mapArea.did, 'code': mapArea.code, 'name': mapArea.name })
            }
            setMapAreasData(mapAreaArray);
        }
        getCategoryData();
        getMapAreaData();
    }, [BROfilter])

    const styles = {
        highlight: {
            ":hover": { color: mobileTheme.palette.secondary.main, textDecoration: 'underline 3px', textUnderlineOffset: '8px' }
        },
        menuItem: {
            py: 0.5,
            px: 2,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: mobileTheme.palette.secondary.main,
                color: mobileTheme.palette.common.white,
            },
        },
    }


    return (
        <Box sx={{ display: 'flex', zIndex: 9999 }}>
            <AppBar component="nav" elevation={0} sx={{ backgroundColor: 'background.default' }}>
                <Toolbar sx={{px:0.5}}>

                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" width={width - 16}> {/* sx={{ background: 'transparent', boxShadow: 'none'}}*/}
                        {/* logo */}
                        <Grid item mt={0.5}>
                            <img src='/images/logo-localoffer4u.png' height={40} alt='' />
                        </Grid>

                        {/* BLD toggle */}
                        {/* <Grid item >
                            {BLDfilter ?
                                <MapIcon fontSize='large' color='secondary' sx={{ cursor: 'pointer' }} onClick={toggleBLDfilter} />
                                :
                                <MapIcon fontSize='large' sx={{ color: '#b3b3b3', cursor: 'pointer' }} onClick={toggleBLDfilter} />
                            }
                        </Grid> */}

                        {/* BRO_AD filter toggle */}
                        {props.info.categoryFilter === true &&
                            <Grid item sx={{float:'left'}}>
                                <Button variant='outlined' 
                                    size='small' 
                                    // sx={{backgroundColor: 'red'}}
                                    onClick={() => toggleBROfilter(BROfilter === '' ? BROCHURE_TYPES.BROCHURE_AD.code : '')}> 
                                        { BROfilterText } 
                                </Button>
                            </Grid>
                        } 
                    </Grid>

                    <Grid container spacing={0} justifyContent="flex-end" alignItems="center" width={width - 16}>
                        
                        {/* map area select */}
                        <Grid item mt={0.5} sx={{float:'right', mr:-1.4}}>
                            <>
                                <Avatar alt="Select Map Area" src="/images/au-map.png" sx={{ cursor: 'pointer' }}
                                    onClick={handleMapClick} ref={anchorRefMap}
                                />
                                <StyledMenu
                                    anchorEl={() => anchorRefMap.current}
                                    open={mapIsOpen}
                                    onClose={handleMapClose}
                                    disableScrollLock={true}
                                    PaperProps={{ style: { width: 160, }, }}
                                    TransitionComponent={Fade}
                                >
                                    <Stack sx={{ boxShadow: 'none' }}>
                                        <ListItem
                                            sx={styles.menuItem}
                                            onClick={() => handleMapAreaSelect('')}>
                                            <Typography color={mobileTheme.palette.text.secondary} fontWeight={300} fontSize={16}>All Areas</Typography>
                                        </ListItem>
                                        {mapAreasData.map(maparea => (
                                            <ListItem
                                                sx={styles.menuItem}
                                                onClick={() => handleMapAreaSelect(maparea)}>
                                                <Typography color={mobileTheme.palette.text.secondary} fontWeight={300} fontSize={16}>{maparea.name}</Typography>
                                            </ListItem>
                                        ))}
                                    </Stack>
                                </StyledMenu>
                            </>

                        </Grid>

                        {/* hamburger menu */}
                        <Grid item mt={0.5} sx={{float:'right'}}>
                            <>
                                <Button id='hamburger-button' 
                                    size='small'
                                    onClick={handleHamburgerMenuClick}
                                    aria-controls={open ? 'hamburger-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                    ref={anchorRefMenu}
                                >
                                    <MenuIcon color={'secondary'} />
                                </Button>
                                {
                                    // props.info.categoryFilter == true &&
                                    <StyledMenu
                                        anchorEl={() => anchorRefMenu.current}
                                        id='hamburger-menu'
                                        open={open}
                                        // anchorEl={mainAnchorEl}
                                        MenuListProps={{
                                            'aria-labelledby': 'hamburger-button'
                                        }}
                                        onClose={onCloseClick}
                                        disableScrollLock={true}
                                        PaperProps={{ style: { width: 200, }, }}
                                        TransitionComponent={Fade}
                                    >
                                        <ListItem onClick={() => { onAllBrochuresClick() }} zIndex={99999999}>
                                            <ListItemIcon>
                                                <Avatar alt="All" src={`/images/category_icons/All_Brochures.png`} sx={{ width: 24, height: 24 }} />
                                            </ListItemIcon>
                                            <ListItemText>All Ads</ListItemText>
                                        </ListItem>

                                        {categories.map((category, index) => {
                                            return (
                                                <div>
                                                    <ListItem
                                                        id={category.code}
                                                        ref={(el) => allRefs.current[index] = el}
                                                        onClick={() => setIsOpenIndex(index)}
                                                        key={category.code} aria-controls={isOpenIndex === index ? category.name : undefined}
                                                        aria-haspopup="true" aria-expanded={isOpenIndex === index ? 'true' : undefined}
                                                    >
                                                        <ListItemIcon>
                                                            <Avatar alt="Food" src={`/images/category_icons/${category.code}.png`} sx={{ width: 24, height: 24 }} />
                                                        </ListItemIcon>
                                                        <ListItemText>{category.name}</ListItemText>
                                                    </ListItem>
                                                    <StyledMenu
                                                        id={category.name}
                                                        open={isOpenIndex === index}
                                                        anchorEl={() => allRefs.current[index]}
                                                        MenuListProps={{
                                                            'aria-labelledby': category.code
                                                        }}
                                                        onClose={() => setIsOpenIndex(-1)}
                                                        PaperProps={{ style: { width: 170, }, }}
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        disableScrollLock={true}
                                                        TransitionComponent={Fade}
                                                    >
                                                        <Stack sx={{ boxShadow: 'none' }}>
                                                            {categories[index].subCategories.map(subCat => {
                                                                return (
                                                                    <ListItem key={subCat.code} onClick={() => { onMenuItemClick(subCat.code); setIsOpenIndex(-1) }}> {subCat.name} </ListItem>
                                                                )
                                                            })}
                                                        </Stack>
                                                    </StyledMenu>
                                                </div>
                                            )
                                        })}

                                        <ListItem onClick={onMapViewClick} >
                                            <ListItemIcon>
                                                <MapOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText>Map View</ListItemText>
                                        </ListItem>

                                        <ListItem onClick={onMapsInPDFclick} >
                                            <ListItemIcon>
                                                <MapOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText>Maps4U in PDF</ListItemText>
                                        </ListItem>

                                    </StyledMenu>
                                }
                            </>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default TitleBarSM;