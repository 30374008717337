import { apiClient } from './axios';

export async function getCategories() {
    let response = await apiClient.get('/rest/category/public/all');
    return response.data;
}

export async function getActiveCategories(broTypes) {
    let response = await apiClient.get(`/rest/category/public/all-active?brochureTypes=${broTypes}`);
    return response.data;
}


