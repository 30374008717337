import { Avatar, Box, Stack, Chip, Grid, Menu, Typography, ListItem, Button } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getActiveCategories, getCategories } from '../shared/services/CategoryService';
import { MobileContext } from '../shared/context/MobileContext';
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import { mobileTheme } from '../themeMobile';
import { removeScrollPosition } from '../shared/util/utils';
import useBrochureService from '../shared/services/BrochureService'
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import { BROCHURE_TYPES } from '../shared/constants/AppConsts';


const TitleBar = (props) => {
    const { getMapAreas } = useBrochureService();
    const [mapAreasData, setMapAreasData] = useState([]);
    const [mapAnchorEl, setMapAnchorEl] = useState(null);
    const { setSelectedCategory, setSelectedMapArea, BLDfilter, toggleBLDfilter, BROfilter, toggleBROfilter, BROfilterText } = useContext(MobileContext);
    // const [showFilter, setShowFilter] = useState('block-inline')
    const [chipsData, setChipsData] = useState([]);
    const [chips, setChips] = useState([]);
    const [refreshChips, setRefreshChips] = useState(1);
    const navigate = useNavigate();

    const allRefs = useRef([]);
    const anchorRefMap = useRef();

    const [isOpenIndex, setIsOpenIndex] = useState(-1);
    const [mapIsOpen, setMapIsOpen] = useState(false);

    useEffect(() => {
        allRefs.current = allRefs.current.slice(0, chipsData.length);
    }, [chipsData]);

    useEffect(() => {
        async function getCategoryData() {
            let chipItems = [];
            const activeTypes = BROfilter ? 'BRO_AD' : 'LP_AD,BRO_AD';
            let data = await getActiveCategories(activeTypes);
            for (let category of data) {
                let subCatsList = [];
                for (let subCat of category.subCategories) {
                    subCatsList.push({ 'code': subCat.code, 'name': subCat.name })
                }
                chipItems.push({ 'code': category.code, 'name': category.name, 'subCategories': subCatsList })
            }
            setChipsData(chipItems);
        }
        async function getData() {
            let mapAreaArray = [];
            let data = await getMapAreas();
            for (let mapArea of data) {
                mapAreaArray.push({ 'did': mapArea.did, 'code': mapArea.code, 'name': mapArea.name })
            }
            setMapAreasData(mapAreaArray);
        }

        getCategoryData();
        getData();
    }, [BROfilter])

    useEffect(() => {
        let chipComponents = [];

        chipsData.map(item => {
            let chipTextColor = 'chipDefault';
            let chipColor = 'background.paper';

            return chipComponents.push(
                <Grid item key={item.code}>
                    <Chip
                        key={item.code}
                        avatar={<Avatar src={`/images/category_icons/${item.code}.png`} />}
                        label={item.name}
                        size='small'
                        sx={{ backgroundColor: chipColor }}
                        color={chipTextColor}
                        onClick={event => handleChipClick(event, item.code)}
                    />
                </Grid>
            )
        })
        setChips(chipComponents)
    }, [chipsData, refreshChips])


    const handleChipClick = (event, clickedOnCategoryCode) => {
        setChipsData(chipsData);
        setRefreshChips(refreshChips + 1)
        removeScrollPosition()
        setSelectedCategory(clickedOnCategoryCode)
        navigate('/');
    }

    const handleAllBrochuresClick = () => {
        removeScrollPosition()
        setSelectedCategory('');
        setSelectedMapArea('');
        navigate('/');
    }

    const handleMapAreaSelect = (mapArea) => {
        setMapAnchorEl(null);
        setMapIsOpen(false);
        setSelectedMapArea(mapArea.did);
    };

    const handleMapClose = (mapArea) => {
        setMapAnchorEl(null);
        setMapIsOpen(false);
    };

    const handleMapClick = (event) => {
        //setMapAnchorEl(event.currentTarget);
        setMapIsOpen(true);
    };

    const ImageGrid = styled('div')({
        // background: `url('https://assets.atdw-online.com.au/images/0bd62076ef5d09b9e01edfefcb9031cf.jpeg?rect=502%2C0%2C3028%2C2271&w=800&h=600&q=eyJ0eXBlIjoibGlzdGluZyIsImxpc3RpbmdJZCI6IjU4MzI5NDI3MmVmNGE5NmQyNWNhNzY5ZSIsImRpc3RyaWJ1dG9ySWQiOiI2NDFkMjhmNGU5ZTRmZGQ1NDE1YzkxMWUiLCJhcGlrZXlJZCI6IjY0MWQyOGY0ZTllNGZkZDU0MTVjOTEyMSJ9')`, // Replace with your image URL
        // backgroundSize: 'cover',
        backgroundColor: mobileTheme.palette.background.default,
        top: 0,
        position: 'fixed',
        zIndex: 1000,
        component: "nav",
        height: '90px',
        width: '100%',
        // backgroundColor: mobileTheme.palette.primary.main,
        display: 'flex',
    });

    const MenuItem = styled('div')({
        fontSize: 14,
        fontWeight: '300',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        cursor: 'pointer',
    });

    // const LogoText = styled(Typography)({
    //     fontSize: 28,
    //     fontWeight: '300',
    //     letterSpacing: '2px',
    //     height: '100%',
    //     justifyItems: 'center',
    //     color: mobileTheme.palette.secondary.main,
    // });

    const styles = {
        highlight: {
            ":hover": { color: mobileTheme.palette.secondary.main, textDecoration: 'underline 3px', textUnderlineOffset: '8px' }
        },
        menuItem: {
            py: 0.5,
            px: 2,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: mobileTheme.palette.secondary.main,
                color: mobileTheme.palette.common.white,
            },
        },
    }


    return (
        <ImageGrid>
            <Grid container justifyContent={'start'} width={'360px'} alignItems={'center'} >
                <Grid item pl={8}>
                    <img src='/images/logo-localoffer4u.png' alt='LocalOffers4U logo' height={'80px'} />
                </Grid>

                {/* BRO_AD filter toggle */}
                {props.info.categoryFilter === true &&
                <Grid item pl={6} >
                    <Button onClick={() => toggleBROfilter(BROfilter === BROCHURE_TYPES.BROCHURE_AD.code ? '' : BROCHURE_TYPES.BROCHURE_AD.code)}> { BROfilterText } </Button>
                </Grid>}

            </Grid>

            {
                props.info.categoryFilter === true
                &&
                <Grid container justifyContent={'end'} p={5} flex={1}>

                    {/* BLD toggle */}
                    {/* <Grid item >
                        {BLDfilter ?
                            <MapIcon fontSize='large' color='secondary' sx={{cursor:'pointer'}} onClick={toggleBLDfilter}/>
                            :
                            <MapIcon fontSize='large' sx={{color:'#b3b3b3', cursor:'pointer'}} onClick={toggleBLDfilter}/>
                        }
                    </Grid> */}

                    {/* Menus */}
                    <Grid item mt={0.6} px={2} py={0.8}>
                        <MenuItem onClick={handleAllBrochuresClick}><Box sx={styles.highlight}>ALL</Box></MenuItem>
                    </Grid>
                    {chipsData.map((category, index) => (
                        <>
                            <Grid item mt={0.6} px={1} py={0.8} key={category.code}>
                                <MenuItem
                                    id={category.name}
                                    ref={(el) => allRefs.current[index] = el}
                                    onClick={() => setIsOpenIndex(index)}
                                    aria-controls={isOpenIndex === index ? category.code : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isOpenIndex === index ? 'true' : undefined}
                                >
                                    <Box sx={styles.highlight}> {category.name} </Box>
                                </MenuItem>
                            </Grid>

                            <Menu
                                id={category.code}
                                anchorEl={() => allRefs.current[index]}
                                open={isOpenIndex === index}
                                onClose={() => setIsOpenIndex(-1)}
                                disableScrollLock={true}
                                MenuListProps={{ 'aria-labelledby': category.name, }}
                                anchorOrigin={{ vertical: 25, horizontal: 'center', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Stack sx={{ boxShadow: 'none' }}>
                                    {category.subCategories.map(subCat => (
                                        <Box
                                            sx={styles.menuItem}
                                            onClick={() => { handleChipClick(null, subCat.code); setIsOpenIndex(-1) }}
                                        >
                                            <Typography color={mobileTheme.palette.text.secondary} fontWeight={300} fontSize={16}>{subCat.name}</Typography>
                                        </Box>
                                    ))}
                                </Stack>
                            </Menu>
                        </>
                    ))}

                    <Grid item mt={0.6} px={2} py={0.8}>
                        <MenuItem onClick={() => navigate('/maps-download')}><Box sx={styles.highlight}>PDF Maps</Box></MenuItem>
                    </Grid>

                    {/* map area selector */}
                    <Grid item px={2} mb={0.8}>
                        <>
                            <Avatar alt="Select Map Area" src="/images/au-map.png" sx={{ cursor: 'pointer' }}
                                onClick={handleMapClick} ref={anchorRefMap}
                            />
                            <Menu
                                anchorEl={() => anchorRefMap.current}
                                open={mapIsOpen}
                                onClose={handleMapClose}
                            >
                                <Stack sx={{ boxShadow: 'none' }}>
                                    <ListItem
                                        sx={styles.menuItem}
                                        onClick={() => handleMapAreaSelect('')}>
                                        <Typography color={mobileTheme.palette.text.secondary} fontWeight={300} fontSize={16}>All Areas</Typography>
                                    </ListItem>
                                    {mapAreasData.map(maparea => (
                                        <ListItem
                                            sx={styles.menuItem}
                                            onClick={() => handleMapAreaSelect(maparea)}>
                                            <Typography color={mobileTheme.palette.text.secondary} fontWeight={300} fontSize={16}>{maparea.name}</Typography>
                                        </ListItem>
                                    ))}
                                </Stack>
                            </Menu>
                        </>
                    </Grid>
                    <Grid item >
                        <PersonIcon fontSize='large' sx={{color:'#b3b3b3', cursor:'pointer'}} onClick={() => navigate('login')}/>
                    </Grid>
                </Grid>
            }

        </ImageGrid>




    )
}

export default TitleBar