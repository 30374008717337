import { SESSION_STORAGE_CONSTS } from "../constants/AppConsts";

export const scrollToTop = () => {
    sessionStorage.setItem(SESSION_STORAGE_CONSTS.SCROLL_POSITION, 0);
    window.scrollTo(0, 0);
};

export const removeScrollPosition = () => {
    sessionStorage.removeItem(SESSION_STORAGE_CONSTS.SCROLL_POSITION);
};

export const ensureHttpsUrl = (url) => {
    const urlPattern = /^(http:\/\/|https:\/\/)/;
    if (urlPattern.test(url)) {
        return url;
    }
    return `https://${url}`;
}

export const removeHtmlScheme = (url) => {
    return url.replace(/(^\w+:|^)\/\//, '');
}
