import React from "react"
import { Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import CategoryIcon from '../shared/components/CategoryIcon';
import { Box } from "@mui/system";
import { mobileTheme } from "../themeMobile";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import { BROCHURE_TYPES } from '../shared/constants/AppConsts'

const BrochureCard = (props) => {

    const styles = {
        gridItemPadding: {
            px: 0.1,
            py: 0.5
        }
    };

    const LandingCard = () => {
        return (
            <Card sx={{ position: 'relative', m: 1.2, borderRadius: 0, backgroundColor: 'white', border: 'none' }} onClick={props.onClick}
                variant={'outlined'}>
                <Box sx={{ position: 'absolute', top: -1.5, left: 7, zIndex: '1', backgroundColor: 'white', color: 'orange', transform: 'rotate(15deg)', px: 1 }} height={36} minWidth={39} width={'auto'} border={3} borderColor={mobileTheme.palette.secondary.main}>
                    <Typography sx={{ mt: .3, textAlign: 'center' }} variant="body2" color="orange" fontWeight="bold">
                        {props.adIndex}
                    </Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 230, right: 10, backgroundColor: '#ef6c00', opacity: '0.75', borderRadius: '25px', color: 'white', transform: 'rotate(0deg)' }} height={28} width={150} >
                    <Typography sx={{ mt: .2, textAlign: 'center' }} variant="body2" color="white" fontWeight="boldx">
                        {props.categoryName}
                    </Typography>
                </Box>

                <Box sx={{backgroundColor: mobileTheme.palette.background.paper}}>
                    {props.img && <CardMedia
                        component="img"
                        height="280"
                        image={props.img}
                    />}
                </Box>

                {!props.img && getCompanyBox()}

                <CardContent>
                    <Box /*height={180}*/ overflow='auto' >
                        <Box height={'100%'} pl={3} borderLeft={4} borderColor={mobileTheme.palette.secondary.main}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item xs={10} sx={{ textTransform: 'uppercase' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {props.title ? props.title : props.companyName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <CategoryIcon categoryCode={props.categoryCode} />
                                </Grid>
                            </Grid>
                            <Typography variant="body2" color="text.secondary">
                                {props.highlights}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="space-around" alignItems="center"></Grid>
                </CardActions>
            </Card>

        )
    }

    const BLDbrochureCard = () => {
        return (
            <Card sx={{ position: 'relative', m: 1.2, borderRadius: 0, backgroundColor: '#F9F9F9', border: 'none', cursor: 'pointer' }} variant="outlined" onClick={props.onClick}>
                <CardContent>
                    <Box height={140} overflow='auto' pl={3} borderLeft={1} borderColor={mobileTheme.palette.secondary.main}>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item xs={11} minHeight={90} >
                                <Typography gutterBottom variant="h6" >
                                    {props.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <OpenInNewIcon color='disabled' fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                <Box sx={{ zIndex: '1', backgroundColor: 'white', px: 1, pt: 0.2 }} height={36} maxWidth={120} border={2} borderColor={mobileTheme.palette.background.paper}>
                                    <Typography sx={{ mt: .3, textAlign: 'center' }} variant="body2" color="gray" fontWeight="bold">
                                        {props.adIndex}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <CategoryIcon categoryCode={props.categoryCode} />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        )
    }


    const getCompanyBox = () => {
        return (
            <Box
                sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px', backgroundColor: mobileTheme.palette.grey[200]
                }} >
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', width: '80%', backgroundColor: mobileTheme.palette.grey[400],
                    borderRadius: '240px'
                }}>
                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 700, color: mobileTheme.palette.background.paper }}>
                        {props.companyName}
                    </Typography>
                </Box>

            </Box>
        )
    }

    return (
        <>
            {props.BLDfilter ?
                <BLDbrochureCard />
                :
                <LandingCard />
            }
        </>
    )
}

export default BrochureCard