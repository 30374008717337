import React from 'react';
import { Box, Typography } from '@mui/material';

const PageHeader = (props) => {
    return (
        <Box>
            <Box display={'inline-flex'} gap={3} mt={2} p={1} pl={3} bgcolor={'grey.100'} width={'100%'}  alignItems={'center'}>
                <Box>{props.icon}</Box>
                <Box display={'inline-block'}>
                    <Typography variant='h5'>{props.title}</Typography>
                    <Typography sx={{ fontStyle: 'italic' }} variant='body2' color={'grey.900'}>{props.subTitle}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default PageHeader