import axios from 'axios'
import { BASE_URL, HTTP_TIMEOUT } from '../../shared/constants/AppConsts'

const useAxiosPrivate = () => {

    const jwtToken = localStorage.getItem('accessToken')

    const apiClient = axios.create({
        baseURL: BASE_URL,
        timeout: HTTP_TIMEOUT,
        headers: {
            'Accept': 'application/json',
            //'Authorization': `Bearer ${jwtToken}`
        }
    });
    
    const apiClientPrivate = axios.create({
        baseURL: BASE_URL,
        timeout: HTTP_TIMEOUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
        }
        // withCredentials: true
    });
    
    /**
     * Catch the AunAuthorized Request
     */
    apiClientPrivate.interceptors.response.use((response) => response, (error) => {
        if (error.request.responseURL.lastIndexOf("/vendor") > 0){
            window.location = '/';
        }
        else if (error.response.status === 401) {
            window.location = '/login';
        }
    });
    
    return { apiClient, apiClientPrivate }

}

export default useAxiosPrivate;