
import { createTheme } from '@mui/material';
import { grey, } from '@mui/material/colors';

export const desktopTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#008080',
            contrastText: '#fff'
        },
        secondary: {
            main: '#FF9800',
            contrastText: '#fff'
        },
        info: {
            main: '#3DBFF2',
            contrastText: '#fff'
        },
        warning: {
            main: '#F2BD1D',
            contrastText: '#fff'
        },
        success: {
            main: '#339933',
            contrastText: '#fff'
        },
        background: {
            paper: '#DFE0DF',
            default: '#fff'
        },
    },
    typography: {
        fontSize: 14,
        // fontFamily: 'Gilroy,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        fontFamily: 'Cocogoose,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    }
})
