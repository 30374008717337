import React, { lazy, Suspense } from 'react'
import BrochureList from './mobile/BrochureList';
import StreetsIndex from './mobile/StreetsIndex';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MobileLayout from './layouts/MobileLayout';
import DesktopLayout from './layouts/DesktopLayout';
import LoginLayout from './layouts/LoginLayout';
import RequireAuth from './shared/components/RequireAuth';
import { Box, Grid } from '@mui/material';
import useWindowDimensions from './shared/components/WindowDimension';
import VendorRecirect from './desktop/vendor/VendorRecirect';
import Ad from './mobile/Ad';
import ArchivedBrochures from './desktop/viewbrochures/ArchivedBrochures';
import TermsAndCondtions from './mobile/TermsAndCondtions';
import PrivacyPolicy from './mobile/PrivacyPolicy';
import VendorLayout from './layouts/VendorLayout';


const BrochureDetail = lazy(() => import('./mobile/BrochureDetail'));
const BrochureDetailBroAd = lazy(() => import('./mobile/BrochureDetailBroAd'))
const MapDownload = lazy(() => import('./mobile/MapDownloadPage'))
const MapContainer = lazy(() => import('./mobile/MapContainer'));
const Vendor = lazy(() => import('./desktop/vendor/Vendor'));
const VendorLandingAdBrochure = lazy(() => import('./desktop/vendor/VendorLandingAdBrochure'));
const VendorGoogleAdBrochure = lazy(() => import('./desktop/vendor/VendorGoogleAdBrochure'));
const VendorBrochureAdBrochure = lazy(() => import('./desktop/vendor/VendorBrochureAdBrochure'))
const ManageBrochures = lazy(() => import('./desktop/managebrochures/ManageBrochures'));
const Brochures = lazy(() => import('./desktop/viewbrochures/Brochures'));
const VendorAds = lazy(() => import('./desktop/viewbrochures/VendorAds'));
const Login = lazy(() => import('./desktop/login/Login'));
const SignUp = lazy(() => import('./desktop/login/SignUp'));


function App() {
    const { height, width } = useWindowDimensions();

    return (
        <Router>
            <Suspense fallback={<Grid height={height} container justifyContent='center' alignItems='center'><Grid item><Box>Loading</Box></Grid></Grid>}>
                <Routes>

                    {/* Login layout */}
                    <Route path='/login' element={<LoginLayout />}>
                        <Route index element={<Login />} />
                    </Route>

                    {/* Mobile layout routes */}
                    <Route path='/vendorRecirect' element={<VendorRecirect />} />
                    <Route path='/ad' element={<Ad />} />

                    {/* Mobile layout routes */}
                    <Route path='/' element={<MobileLayout info={{categoryFilter: true}}/>}>
                        <Route index element={<BrochureList />} />
                        <Route path='brochureMap' element={<MapContainer />} />
                        <Route path='/terms-and-conditions' element={<TermsAndCondtions />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} /> 
                        <Route path='/maps-download' element={<MapDownload />} />
                    </Route>
                    <Route path='/' element={<MobileLayout info={{categoryFilter: false}} />}>
                        <Route path='details/:id' element={<BrochureDetail />} />
                        <Route path='details/brochure-ad/:id' element={<BrochureDetailBroAd />} />
                        <Route path='/streets-index' element={<StreetsIndex />} />
                        
                    </Route>

                    {/* Desktop layout routes */}
                    <Route element={<RequireAuth />}>
                        <Route path='/admin' element={<DesktopLayout />}>
                            <Route path='brochure/manage/:id' element={<VendorLandingAdBrochure />} />
                            <Route path='brochure/google-ad/manage/:id' element={<VendorGoogleAdBrochure />} />
                            <Route path='brochure/brochure-ad/manage/:id' element={<VendorBrochureAdBrochure />} />
                            <Route index element={<Vendor />} />
                            <Route path='brochures'>
                                <Route index element={<Brochures />} />
                                <Route path='archived' element={<ArchivedBrochures />} />
                            </Route>
                            <Route path='manage-brochures' element={<ManageBrochures />} />
                        </Route>
                    </Route>

                    {/* vendor related */}
                    <Route element={<LoginLayout />}>
                        <Route path='/vendor/register/:user/:id' element={<SignUp />} />
                    </Route>    
                    <Route element={<RequireAuth />}>
                        <Route path='/vendor' element={<VendorLayout />}>
                            <Route path='brochure/manage/:id' element={<VendorLandingAdBrochure />} />
                            <Route path=':vendorId/brochures' element={<VendorAds />} />
                            <Route path='manage-brochures' element={<ManageBrochures />} />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </Router >
    );
}

export default App;