import React from 'react'
import { TextField } from '@mui/material'

// const StyledTextField = styled(TextField, {})({
//     width: '100%',
// })

const FormInput = (props) => {
    return (
        <TextField
            InputProps={{
                readOnly: props.readOnly
            }}
            color='secondary'
            name={props.name}
            size='small' sx={{ backgroundColor: '#fff' }}
            label={props.label}
            //value={props.value}
            value={props.value || ''} 
            onChange={props.onChange}
            fullWidth
            type={props.type ? props.type : 'text'}
            rows={props.rows}
            multiline={props.multiline}
            // inputRef={props.ref}
            required={props.required}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            {...(props.error && { error: true, helperText: props.error })}
        />
    )
}

export default FormInput