import React from "react"
import { Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import CategoryIcon from '../shared/components/CategoryIcon';
import { Box } from "@mui/system";
import { mobileTheme } from "../themeMobile";
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LanguageIcon from '@mui/icons-material/Language';
import { ensureHttpsUrl, removeHtmlScheme } from "../shared/util/utils";


const BrochureCardBroAd = (props) => {

    const styles = {
        gridItemPadding: {
            px: 0.1,
            py: 0.5
        }
    };

    const openWeb = (link) => {
        window.open(ensureHttpsUrl(link), "_blank");
    }

    const dial = (phoneNo) => {
        window.location.href = `tel:${phoneNo}`;
    }

    const BroAdCard = () => {
        return (
            <Card sx={{ position: 'relative', m: 1.2, borderRadius: 0, backgroundColor: 'white', border: 'none' }}
                variant={'outlined'}>
                <Box sx={{ position: 'absolute', top: -1.5, left: 7, zIndex: '1', backgroundColor: 'white', color: 'orange', transform: 'rotate(15deg)', px: 1 }} height={36} minWidth={39} width={'auto'} border={3} borderColor={mobileTheme.palette.secondary.main}>
                    <Typography sx={{ mt: .3, textAlign: 'center' }} variant="body2" color="orange" fontWeight="bold">
                        {props.adIndex}
                    </Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 230, right: 10, backgroundColor: '#ef6c00', opacity: '0.75', borderRadius: '25px', color: 'white', transform: 'rotate(0deg)' }} height={28} width={150} >
                    <Typography sx={{ mt: .2, textAlign: 'center' }} variant="body2" color="white" fontWeight="boldx">
                        {props.categoryName}
                    </Typography>
                </Box>

                <Box sx={{ backgroundColor: mobileTheme.palette.background.paper }} onClick={props.onClick}>
                    {props.img && <CardMedia
                        component="img"
                        height="280"
                        image={props.img}
                    //alt={props.title}
                    />}
                </Box>

                {!props.img && getCompanyBox()}

                <CardContent>
                    <Box /*height={180}*/ overflow='auto' >
                        <Box height={'100%'} pl={3} borderLeft={4} borderColor={mobileTheme.palette.secondary.main}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item xs={10} sx={{ textTransform: 'uppercase' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {props.title ? props.title : props.companyName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <CategoryIcon categoryCode={props.categoryCode} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography variant="body2" color="text.secondary">
                                        {props.highlights}
                                    </Typography>
                                </Grid>

                                {/* actions */}
                                <Grid xs={12} pt={2}>
                                    
                                    <Grid container alignItems={'center'} pt={1}>
                                        <Grid item xs={2} al><StorefrontIcon color="secondary" /></Grid>
                                        <Grid item xs={10}><Typography variant="body2" color='text.secondary'>{props.companyName.toUpperCase()}</Typography></Grid>
                                    </Grid>
                                    
                                    { props.contactPhone && 
                                        <Grid container alignItems={'center'} pt={1} onClick={() => dial(props.contactPhone)}>
                                            <Grid item xs={2} al><PhoneIcon color="secondary" /></Grid>
                                            <Grid item xs={10}><Typography variant="body2" color={mobileTheme.palette.primary.main}>{props.contactPhone}</Typography></Grid>
                                        </Grid>
                                    }
                                    
                                    { props.wwwUrl && 
                                        <Grid container alignItems={'center'} pt={1} onClick={() => openWeb(props.wwwUrl)}>
                                            <Grid item xs={2} al><LanguageIcon color="secondary" /></Grid>
                                            <Grid item xs={10}><Typography variant="body2" color={mobileTheme.palette.primary.main} sx={{ textDecoration: 'underline' }}>{removeHtmlScheme(props.wwwUrl)}</Typography></Grid>
                                        </Grid>
                                    }
                                </Grid>


                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="space-around" alignItems="center"></Grid>
                </CardActions>
            </Card>

        )
    }


    const getCompanyBox = () => {
        return (
            <Box onClick={props.onClick}
                sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px', backgroundColor: mobileTheme.palette.grey[200]
                }} >
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', width: '80%', backgroundColor: mobileTheme.palette.grey[400],
                    borderRadius: '240px'
                }}>
                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 700, color: mobileTheme.palette.background.paper }}>
                        {props.companyName}
                    </Typography>
                </Box>

            </Box>
        )
    }

    return (<BroAdCard />);
}

export default BrochureCardBroAd