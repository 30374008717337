import { Container, ThemeProvider, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import DesktopFooter from '../shared/components/DesktopFooter'
import LoginAppBar from '../shared/components/LoginAppBar'
import { desktopTheme } from '../themeDesktop'

const LoginLayout = () => {
    const aboveSM = useMediaQuery(desktopTheme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={desktopTheme}>
            <section className='desktop-background'>
                <LoginAppBar />
                <Container>
                    <Outlet />
                </Container>
                {aboveSM && <DesktopFooter />}

            </section>
        </ThemeProvider>
    )
}

export default LoginLayout